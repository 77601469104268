import {post} from '@/utils/axios'

// 心理 & 健康测评
export const getThreeTest = (params) => post('/shida-user-biz/Health/getTeacherManagerStudentEva',params)

export const getTestDetail = (params) => post('/shida-user-biz/TbEvaluation/getEvaResultDetailByUserId',params)

export const hurryUp = (params) => post('/shida-user-biz/TbMessagePerson/saveOrUpdate',params)

// 体育比赛
export const getCompetionList = (params) => post('/shida-user-biz/TbMatch/getClassSportMatchList',params)

export const getCompetionDetail = (params) => post('/shida-user-biz/TbMatch/getSportMatchDetail',params)

export const addCompetion = (params) => post('/shida-user-biz/TbMatch/saveOrUpdateSportMatch',params)

export const removeCompetion = (params) => post('/shida-user-biz/TbMatch/deleteSportMatch',params)

export const getCompetionById = (params) => post('/shida-user-biz/TbMatch/getUserSportMatchList',params)

// 成绩汇总
export const healthList = (params) => post('/shida-user-biz/TbBreak/getClassBodyInfo',params)

export const healthByUserId = (params) => post('/shida-user-biz/TbBreak/getBodyInfo',params)

export const getHealth = (params) => post('/shida-user-biz/Health/getHealth',params)

export const getStudentTest = (params) => post('/shida-user-biz/Health/getStudentArtEva',params)

export const addRaceGroup = params => post('/shida-user-biz/TbMatch/saveOrUpdateBatchSportMatch', params)

// 卫生违纪
export const getWeijiList = params => post('/shida-user-biz/TbBreak/getClassHealthBreakList', params)

export const getWejiDetail = params => post('/shida-user-biz/TbBreak/getHealthBreakDetail', params)

export const addWeiji = params => post('/shida-user-biz/TbBreak/saveOrUpdateHealthBreak', params)

export const removeWeiji = params => post('/shida-user-biz/TbBreak/deleteHealthBreak', params)

export const revokeWeiji = params => post('/shida-user-biz/TbBreak/revokeHealthBreak', params)

export const recoveryWeiji = params => post('/shida-user-biz/TbBreak/renewHealthBreak', params)

export const getWeijiByUserId = params => post('/shida-user-biz/TbBreak/getUserHealthBreakList', params)

// 体育出勤率
export const getStudentStatus = params => post('/shida-user-biz/TbDic/getDicByType', params)

export const importExcel = params => post('/shida-user-biz/TbSportattend/saveOrUpdate', params)

export const getAttendance = params => post('/shida-user-biz/TbSportattend/getData', params)

export const updateAttendance = params => post('/shida-user-biz/TbSportattend/updateUserSportAttend', params)

// export const getAttendanceDetail = params => post('/shida-user-biz/TbSportattend/getDataByUserId', params)

export const getAttendanceDetail = params => post('/shida-user-biz/TbAttendanceStudent/getData', params)

export const getSports = params => post('/shida-user-biz/TbAttendanceGrmClass/getData', params)

export const saveSports = params => post('/shida-user-biz/TbAttendanceGrmClass/saveOrUpdate', params)

// 删除体育课考勤
export const delSports = params => post('/shida-user-biz/TbAttendanceGrmClass/remove', params)

export const getDuring = params => post('/shida-user-biz/TbAttendanceBreakoutClass/getData', params)

export const saveDuring = params => post('/shida-user-biz/TbAttendanceBreakoutClass/saveOrUpdate', params)

// 删除课间操考勤
export const delDuring = params => post('/shida-user-biz/TbAttendanceBreakoutClass/remove', params)

export const getClassById = params => post('/shida-user-center/SysDept/getClassStudent2', params)

// 体质检测成绩
export const getConstitution = params => post('/shida-user-biz/TbPhysiqueUser/getNoPageData', params)

export const importConstitution = params => post('/shida-user-biz/TbPhysiqueUser/addImportData', params)

export const saveConstitution = params => post('/shida-user-biz/TbPhysiqueUser/saveOrUpdate', params)

// 中考体育 - 中考体育过程性考核成绩
export const midPhyProcessScore = params => post('/shida-user-biz/TbMiddleExamPhysicalScore/getList', params)

// 中考体育 - 体育出勤率
export const midPhyAttendance = params => post('/shida-user-biz/TbSportattend/getMiddleSportAttend', params)

// 中考体育 - 学生体质健康测试
export const midPhyHealthTest = params => post('/shida-user-biz/TbPhysiqueUser/getMiddlePhysique', params)

// 中考体育 - 专项运动技能考核
export const midPhySportExam = params => post('/shida-user-biz/api/TbSpecialSportSkillsProjectUserScore/getMidScore', params)

// 中考体育 - 专项运动技能考核 - 模板下载
export const midPhySportExamTplDl = params => post('/shida-user-biz/TbSpecialSport/exportExcel', params, 'blob')

// 中考体育 - 专项运动技能考核 - 导入
export const midPhySportExamImport = params => post('/shida-user-biz/TbSpecialSport/importScore', params)

// 中考体育 - 专项运动技能考核 - 学生详情
export const midPhySportExamStuDetail = params => post('/shida-user-biz/api/TbSpecialSportSkillsProjectUserScore/getList', params)

// 中考体育 - 学生端详情
export const stuPhyMidExamDetail = params => post('/shida-user-biz/api/TbSpecialSportSkillsProjectUserScore/getUserHighSchoolScore', params)

// 体育比赛审核
export const phyAudit = params => post('/shida-user-biz/TbMatch/updateAuditStatus', params)
